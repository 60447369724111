import { useTheme } from "@react-navigation/native";

export function useAppTheme() {
  const theme = useTheme();
  return {
    dark: theme.dark,
    colors: {
      ...theme.colors,
      placeholderColor: theme.dark ? "#999999" : "#333333",
    },
  };
}
