import { LocalStore } from "../../api/LocalStore";
import { AuthAction } from "./types";

export function login(token: string): AuthAction {
  LocalStore.setToken(token);
  return {
    type: "SIGN_IN",
    token,
  };
}

export function restoreToken(token: string | null): AuthAction {
  return {
    type: "RESTORE_TOKEN",
    token,
  };
}

export function logout(): AuthAction {
  LocalStore.clear();
  return {
    type: "SIGN_OUT",
  };
}
