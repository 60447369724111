import { useTheme } from "@react-navigation/native";
import React from "react";
import { TextInputProps } from "react-native";
import { TextInput } from "react-native-gesture-handler";

export function NTextInput(props: TextInputProps) {
  const { colors } = useTheme();
  return (
    <TextInput
      {...props}
      style={[{ color: colors.text }, props.style]}
    ></TextInput>
  );
}
