import React from "react";
import { StyleSheet, TextStyle } from "react-native";
import { NText } from "./NText";

export function SectionTitle(props: { children: any; style?: TextStyle }) {
  return (
    <NText style={[styles.groupTitle, props.style]}>{props.children}</NText>
  );
}
const styles = StyleSheet.create({
  groupTitle: { fontWeight: "600", fontSize: 24, marginTop: 8 },
});
