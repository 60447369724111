import { Song } from "../../models/Song";
import { SongsState, SongAction } from "./types";

const initialState = {
  items: [
    {
      title: "Baris Akarsu - Islak Islak",
      artist: "Baris Akarsu",
      name: "Islak Islak",
      image: "https://i.scdn.co/image/ab67616d00001e0208beaed79c1fcfec40f7d51c",
      trackId: "3xHM0KckpQ6duQ2NNcY4LM",
      previewUrl:
        "https://p.scdn.co/mp3-preview/b59824383fcc2b05d51f1265c40aa7a5707037c9?cid=774b29d4f13844c495f206cafdad9c86",
      content: `Am                    G        Am
Gecenin nemi mi düşmüş gözlerine
Am                   G         Am  
Ne olur ıslak ıslak   bakma öyle
Am         Dm     C              
Saçını dök sineme derdini söyle
Am                    G          Am
Yeter ki ıslak ıslak   bakma  öyle
Am                C                          
Sürerim buluttan  tarlaları
Dm                 G           Am
Yağmurlar ekerim   göğün göğsüne
Am               C
Güneşte demlerim senin çayını  
Dm               G          Am 
Yüreğimden süzer öyle veririm 
Am                     G           Am
Ben feleğin şu çarkına çomak sokarım
Am                     G           Am
Ben feleğin tekerine   çomak sokarım    
Am                    G        Am 
Yeter ki ıslak ıslak  bakma öyle`,
    },
  ] as Song[],
} as SongsState;

export function reducer(state = initialState, action: SongAction) {
  switch (action.type) {
    /* case "DELETE_ITEM":
        return state.items.filter((item) => item.id !== action.id); */
    case "ADD_ITEM":
      return { ...state, items: [...state.items, action.song] };
    default:
      return state;
  }
}
