import * as React from "react";
import { Provider } from "react-redux";
import { store } from "./store";

import { AppNavigator } from "./AppNavigator";
import { QueryCache, ReactQueryCacheProvider } from "react-query";

const queryCache = new QueryCache();
function App() {
  return (
    <Provider store={store}>
      <ReactQueryCacheProvider queryCache={queryCache}>
        <AppNavigator />
      </ReactQueryCacheProvider>
    </Provider>
  );
}

export default App;
