import { Song } from "notabook-shared/models/Song";

export type RootStackParamList = {
  Home: {};
  AddSong: {};
  SongDetail: { id: string };
  Playlist: { id: string };
  Login: {};
  Register: {};
  Loading: {};
  EditSong: { id: string };
};

export const RootLinking = {
  prefixes: ["https://notabook.cancit.com", "notabook://"],
  config: {
    screens: {
      Login: "login",
      Register: "register",
      Home: "",
      Profile: ":id/profile",
      Settings: ":id/blog",
      AddSong: "song/new",
      SongDetail: "song/:id",
      Playlist: "playlist/:id",
      EditSong: "song/:id/edit",
    },
  },
};
