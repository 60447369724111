import React, { useLayoutEffect, useEffect, useState } from "react";
import { StyleSheet, View, ScrollView, Text } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { StackScreenProps } from "@react-navigation/stack";
import { PinchGestureHandler } from "react-native-gesture-handler";

import { RootStackParamList } from "../../route";
import { SpotifyCard } from "../../components/spotifyCard";
import { useDebounce } from "use-debounce";
import { SpotifyLinkModal } from "../../components/spotifySearchModal";
import { useSongs } from "../../hooks/useSongs";
import { ISong } from "notabook-shared/models/Song";
import { useTheme } from "@react-navigation/native";
import { NText } from "../../components/NText";

interface Props extends StackScreenProps<RootStackParamList, "SongDetail"> {}

export default function Detail(props: Props) {
  const songId = props.route.params.id;
  const [song, setSong] = useState<ISong>();

  const { data, refetch } = useSongs({ enabled: false });

  const [fontSize, setFontSize] = useState(20);
  const [value] = useDebounce(fontSize, 5);
  const [spotifyPicker, setSpotifyPicker] = useState(false);

  const { colors } = useTheme();
  useEffect(() => {
    const songsData = data?.songs.find((s) => s._id === songId);
    if (songsData) {
      setSong(songsData);
    } else {
      refetch();
    }
  }, [data]);

  useLayoutEffect(() => {
    props.navigation.setOptions({
      headerRight: () => (
        <TouchableOpacity
          style={{ marginRight: 24 }}
          onPress={() => {
            /* setSpotifyPicker(true) */
            props.navigation.navigate("EditSong", { id: songId });
          }}
        >
          <NText>Edit</NText>
        </TouchableOpacity>
      ),
    });
  }, [props.navigation]);

  if (!song) {
    return null;
  }
  const output = song.content.split(/(\s)/g).map((item, i) => {
    if (
      /(\b([CDEFGAB](?:b|bb|m)*(?:#|##|sus|maj|min|aug|#m|#dim)*[\d\/]*(?:[CDEFGAB](?:b|bb|m)*(?:#|##|sus|maj|min|aug|#m|#dim)*[\d\/]*)*)(?=\s|$)(?! \w))/g.test(
        item
      )
    ) {
      return (
        <Text key={i} style={{ color: "red", fontWeight: "600" }}>
          {item}
        </Text>
      );
    }
    return item;
  });

  return (
    <View style={[styles.container, { backgroundColor: colors.background }]}>
      {song._id && (
        <SpotifyLinkModal
          title={song.title}
          onClose={() => setSpotifyPicker(false)}
          visible={spotifyPicker}
        />
      )}
      <ScrollView contentContainerStyle={{ paddingVertical: 8 }}>
        <View
          style={{
            maxWidth: 960,
            width: "100%",
            alignSelf: "center",
          }}
        >
          <SpotifyCard song={song} />
          <PinchGestureHandler
            onGestureEvent={(event) => {
              //      if (event.nativeEvent.state === State.ACTIVE) {
              const newValue = Math.round(
                Math.max(Math.min(20 * event.nativeEvent.scale, 72), 10)
              );
              if (newValue !== fontSize) {
                setFontSize(newValue);
              }
            }}
          >
            <Text
              style={{
                padding: 4,
                marginTop: 8,
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: colors.text,
                color: colors.text,
                height: "100%",
                flex: 1,
                alignSelf: "stretch",
                borderRadius: 4,
                textAlignVertical: "top",
                fontSize: value,
              }}
            >
              {output}
            </Text>
          </PinchGestureHandler>
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    // paddingVertical: 8,
    flex: 1,
    backgroundColor: "#fff",
    paddingHorizontal: 8,
  },
});
