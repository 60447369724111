import React, { useEffect, useRef } from "react";
import { Song } from "notabook-shared/models/Song";
import { View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Audio } from "expo-av";
import { NText } from "./NText";
export function SongPreview(props: { song: Song }) {
  const { song } = props;
  const soundObject = useRef<Audio.Sound>();
  useEffect(() => {
    return () => {
      if (soundObject.current) {
        soundObject.current.unloadAsync();
      }
    };
  }, []);
  const play = async () => {
    if (!song.previewUrl) {
      return;
    }
    // Linking.openURL(`spotify:track:${song.trackId}`);
    try {
      if (soundObject.current) {
        const status = await soundObject.current.getStatusAsync();
        if (status.isLoaded) {
          console.log(status);
          if (status.isPlaying) {
            soundObject.current.pauseAsync();
          } else {
            console.log("playAsync");

            soundObject.current.replayAsync();
          }
        }
      } else {
        soundObject.current = new Audio.Sound();
        await soundObject.current.loadAsync({
          uri: song.previewUrl,
        });
        await soundObject.current.playAsync();
      }
    } catch (error) {
      console.warn(error);
    }
  };
  if (!song.previewUrl) {
    return null;
  }
  return (
    <View style={{ flexDirection: "row" }}>
      <TouchableOpacity
        style={{
          alignSelf: "center",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
          marginLeft: 36,
          marginRight: 16,
        }}
        onPress={play}
      >
        <NText style={{ color: "red", fontWeight: "800" }}>Play</NText>
      </TouchableOpacity>
    </View>
  );
}
