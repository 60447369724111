import React, { useEffect, useState } from "react";
import {
  KeyboardAvoidingView,
  Modal as NativeModal,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { TextInput } from "react-native-gesture-handler";
import { NotabookAPI } from "../api/NotabookAPI";
import { usePlaylists } from "../hooks/usePlaylists";
import { navigateToPlaylist } from "../navigate";

const Modal = Platform.select({
  web: require("modal-react-native-web"),
  default: NativeModal,
});
export function NewPlaylistModal(props: {
  visible: boolean;
  onClose: () => void;
}) {
  const [visible, setVisible] = useState(false);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const playlists = usePlaylists({ enabled: false });
  useEffect(() => {
    if (props.visible) {
      //  search();
      setVisible(true);
    }
  }, [props.visible]);

  const createPlaylist = () => {
    if (name.length === 0) {
      return;
    }
    NotabookAPI.createPlaylist({ name, description }).then((res) => {
      console.log(res);
      props.onClose();
      setVisible(false);
      playlists.refetch();
      navigateToPlaylist((res as any)._id);
    });
  };
  return (
    <Modal visible={visible} transparent animationType="fade">
      <View
        style={{
          ...StyleSheet.absoluteFillObject,
          backgroundColor: "black",
          opacity: 0.4,
        }}
      />
      <KeyboardAvoidingView
        behavior={"padding"}
        style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
      >
        <View
          style={{
            width: "100%",
            maxWidth: 600,
            alignSelf: "center",
          }}
        >
          <View
            style={{
              margin: 16,
              padding: 16,
              borderRadius: 4,
              backgroundColor: "#eeeeee",
            }}
          >
            <Text style={{ fontWeight: "600", fontSize: 20 }}>
              Create Playlist
            </Text>
            <TextInput
              placeholder="Name"
              style={{
                borderWidth: 1,
                borderStyle: "solid",
                padding: 4,
                borderRadius: 4,
                marginTop: 12,
                alignSelf: "stretch",
              }}
              onChangeText={(text) => setName(text)}
            />
            <TextInput
              multiline
              placeholder="Description"
              style={{
                alignSelf: "stretch",
                padding: 4,
                marginTop: 8,
                borderWidth: 1,
                borderStyle: "solid",
                borderRadius: 4,
                textAlignVertical: "top",
                minHeight: 72,
              }}
              onChangeText={(text) => setDescription(text)}
            />
            <View style={{ flexDirection: "row-reverse" }}>
              <TouchableOpacity
                style={{ marginVertical: 12, marginHorizontal: 12 }}
                onPress={() => {
                  props.onClose();
                  setVisible(false);
                }}
              >
                <Text style={{}}>Close</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={{ marginVertical: 12, marginHorizontal: 12 }}
                onPress={createPlaylist}
                disabled={name.length === 0}
              >
                <Text style={{}}>Create</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </KeyboardAvoidingView>
    </Modal>
  );
}
