import React from "react";
import { Song } from "notabook-shared/models/Song";
import { Image, View, Text } from "react-native";

interface Props {
  song: Song;
}
export function SongImage(props: Props) {
  const { song } = props;
  if (song.image) {
    return (
      <Image
        style={{ height: 64, width: 64, borderRadius: 4 }}
        source={{ uri: song.image }}
      />
    );
  }
  return (
    <View
      style={{
        height: 64,
        width: 64,
        borderRadius: 4,
        backgroundColor: "gray",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Text
        style={{
          fontSize: 36,
        }}
      >
        {song.name && song.name.substr(0, 2)}
      </Text>
    </View>
  );
}
