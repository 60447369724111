import { useTheme } from "@react-navigation/native";
import { IPlaylist } from "notabook-shared/models/Playlist";
import React from "react";
import { Dimensions, StyleSheet, TouchableOpacity, View } from "react-native";
import { NText } from "../../components/NText";

export function PlaylistHeader({ playlist }: { playlist: IPlaylist }) {
  const { colors } = useTheme();
  const { width, height } = Dimensions.get("window");
  const { coverSize, titleFontSize, rootFlexDirection } = getHeaderStyles(
    width,
    height
  );
  return (
    <View style={[styles.root, { flexDirection: rootFlexDirection }]}>
      <View style={[styles.cover, { width: coverSize, height: coverSize }]}>
        <NText style={styles.coverText}>{playlist.name.substr(0, 2)}</NText>
      </View>
      <View
        style={{
          marginLeft: rootFlexDirection === "row" ? 16 : 0,
          alignSelf: "center",
          alignItems: rootFlexDirection === "row" ? "flex-start" : "center",
        }}
      >
        <NText
          style={{
            fontSize: titleFontSize,
            fontWeight: "600",
            marginBottom: 4,
          }}
        >
          {playlist.name}
        </NText>
        <NText>{playlist.description}</NText>
        <TouchableOpacity
          // onPress={() => addToPlaylist(item._id!)}
          style={[styles.addToQueue, { borderColor: colors.text }]}
        >
          <NText>ADD ALL TO QUEUE</NText>
        </TouchableOpacity>
      </View>
    </View>
  );
}

function getHeaderStyles(width: number, height: number) {
  if (width > 600) {
    return {
      coverSize: 128,
      titleFontSize: 48,
      rootFlexDirection: "row" as any,
    };
  }
  return {
    coverSize: 96,
    titleFontSize: 24,
    rootFlexDirection: "column" as any,
  };
}

const styles = StyleSheet.create({
  cover: {
    width: 128,
    height: 128,
    backgroundColor: "#c3c3c3",
    alignItems: "center",
    justifyContent: "center",
  },
  coverText: {
    fontSize: 36,
    color: "black",
  },
  addToQueue: {
    justifyContent: "center",
    borderWidth: 1,
    borderRadius: 16,
    paddingVertical: 4,
    paddingHorizontal: 24,
    marginTop: 12,
  },
  root: {
    flexDirection: "row",
    marginBottom: 24,
    alignItems: "center",
  },
});
