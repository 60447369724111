import React, { useEffect, useState } from "react";
import { StyleSheet, View, ScrollView } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { StackScreenProps } from "@react-navigation/stack";
import { RootStackParamList } from "../../route";
import { useSongs } from "../../hooks/useSongs";
import { usePlaylists, usePlaylistSongs } from "../../hooks/usePlaylists";
import { IPlaylist } from "notabook-shared/models/Playlist";
import { SectionTitle } from "../../components/sectionTitle";
import { NotabookAPI } from "../../api/NotabookAPI";
import { useTheme } from "@react-navigation/native";
import { NText } from "../../components/NText";
import { PlaylistHeader } from "./PlaylistHeader";

interface Props extends StackScreenProps<RootStackParamList, "Playlist"> {}

export default function Playlist(props: Props) {
  const playlistId = props.route.params.id;
  const [playlist, setPlaylist] = useState<IPlaylist>();

  const { data, isFetched, refetch } = usePlaylists({ enabled: false });
  const playlistSongs = usePlaylistSongs(playlistId);

  const { data: songsData } = useSongs();

  const { colors } = useTheme();
  useEffect(() => {
    const songsData = data?.playlists.find((s) => s._id === playlistId);
    if (songsData) {
      setPlaylist(songsData);
    } else if (!isFetched) {
      refetch();
    }
  }, [data]);

  const addToPlaylist = (songId: string) => {
    NotabookAPI.addSongToPlaylist({ playlistId: playlist!._id!, songId }).then(
      (res) => {
        console.log(res);
        playlistSongs.refetch();
      }
    );
  };
  if (!playlist || !playlistSongs.isFetched) {
    return null;
  }

  const recommendedSongs = songsData?.songs
    .filter((s) => !playlistSongs.data?.songs.find((ss) => ss._id === s._id))
    .sort((a, z) => a.name!.localeCompare(z.name!));

  return (
    <View style={styles.container}>
      <ScrollView contentContainerStyle={{ paddingVertical: 8 }}>
        <View
          style={{
            maxWidth: 960,
            width: "100%",
            alignSelf: "center",
          }}
        >
          <PlaylistHeader playlist={playlist} />
          {playlistSongs.isFetched && playlistSongs.data?.songs.length === 0 && (
            <View
              style={{
                alignItems: "center",
                height: 200,
                justifyContent: "center",
              }}
            >
              <NText style={{ fontSize: 28 }}>{"Start adding songs!"}</NText>

              <NText style={{ marginTop: 20 }}>
                {"Add recommended songs, or search for a song and add it"}
              </NText>
            </View>
          )}
          {(playlistSongs.data?.songs.length || 0) > 0 && (
            <View>
              <View style={{ flexDirection: "row", marginVertical: 4 }}>
                <NText
                  style={{
                    flex: 2,
                  }}
                >
                  TITLE
                </NText>
                <NText
                  style={{
                    flex: 1,
                  }}
                >
                  ARTIST
                </NText>
                <NText
                  style={{
                    flex: 1,
                  }}
                >
                  ADDED BY
                </NText>
                <NText
                  style={{
                    flex: 1,
                  }}
                >
                  ADDED AT
                </NText>
              </View>
              {playlistSongs.data?.songs.map((item) => {
                return (
                  <TouchableOpacity
                    key={item._id}
                    onPress={() => {
                      props.navigation.navigate("SongDetail", {
                        id: item._id!,
                      });
                    }}
                    style={{
                      flexDirection: "row",
                      marginVertical: 4,
                      alignItems: "center",
                    }}
                  >
                    {item.name && item.artist ? (
                      <>
                        <NText
                          style={{
                            flex: 2,
                            fontSize: 18,
                            marginVertical: 4,
                            fontWeight: "600",
                          }}
                        >
                          {item.name}
                        </NText>
                        <NText
                          style={{
                            flex: 1,
                            fontSize: 16,
                          }}
                        >
                          {item.artist}
                        </NText>
                      </>
                    ) : (
                      <NText
                        style={{
                          marginLeft: 12,
                          fontSize: 18,
                          marginVertical: 4,
                        }}
                      >
                        {item.title}
                      </NText>
                    )}
                    <NText
                      style={{
                        flex: 1,
                        fontSize: 16,
                      }}
                    >
                      {item.addedBy.username}
                    </NText>
                    <NText
                      style={{
                        flex: 1,
                        fontSize: 16,
                      }}
                    >
                      {item.addedAt.substring(0, 10)}
                    </NText>
                  </TouchableOpacity>
                );
              })}
            </View>
          )}
          {(recommendedSongs?.length || 0) > 0 && (
            <View style={{ marginTop: 24 }}>
              <SectionTitle style={{ marginBottom: 12 }}>
                Recommended Songs
              </SectionTitle>
              <View style={{ flexDirection: "row", marginVertical: 4 }}>
                <View
                  style={{
                    width: 92,
                  }}
                />
                <NText
                  style={{
                    flex: 2,
                  }}
                >
                  TITLE
                </NText>
                <NText
                  style={{
                    flex: 1,
                  }}
                >
                  ARTIST
                </NText>
              </View>
              {recommendedSongs?.map((item) => {
                return (
                  <View
                    key={item._id}
                    /*  onPress={() => {
                    props.navigation.navigate("SongDetail", { id: item._id! });
                  }} */
                    style={{
                      flexDirection: "row",
                      marginVertical: 4,
                      alignItems: "center",
                    }}
                  >
                    <View style={{ justifyContent: "center" }}>
                      <TouchableOpacity
                        onPress={() => addToPlaylist(item._id!)}
                        style={{
                          justifyContent: "center",
                          borderWidth: 1,
                          borderRadius: 16,
                          paddingVertical: 4,
                          borderColor: colors.text,
                          paddingHorizontal: 24,
                        }}
                      >
                        <NText allowFontScaling={false}>ADD</NText>
                      </TouchableOpacity>
                    </View>
                    {item.name && item.artist ? (
                      <>
                        <NText
                          style={{
                            flex: 2,
                            marginLeft: 12,
                            fontSize: 18,
                            marginVertical: 4,
                            fontWeight: "600",
                          }}
                        >
                          {item.name}
                        </NText>
                        <NText
                          style={{
                            flex: 1,
                            fontSize: 16,
                          }}
                        >
                          {item.artist}
                        </NText>
                      </>
                    ) : (
                      <NText
                        style={{
                          marginLeft: 12,
                          fontSize: 18,
                          marginVertical: 4,
                        }}
                      >
                        {item.title}
                      </NText>
                    )}
                  </View>
                );
              })}
            </View>
          )}
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 8,
  },
});
