import { useTheme } from "@react-navigation/native";
import React, { useState } from "react";
import { ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { NewPlaylistModal } from "../../../components/newPlaylistModal";
import { NText } from "../../../components/NText";
import { usePlaylists } from "../../../hooks/usePlaylists";
export function PlaylistSection(props: { navigation: any }) {
  const { data } = usePlaylists();
  const [newPlaylistModalVisible, setNewPlaylistModalVisible] = useState(false);
  const { colors } = useTheme();

  return (
    <>
      {newPlaylistModalVisible && (
        <NewPlaylistModal
          visible={newPlaylistModalVisible}
          onClose={() => setNewPlaylistModalVisible(false)}
        />
      )}
      <NText style={styles.groupTitle}>Playlist</NText>
      <ScrollView
        horizontal
        style={{ flexDirection: "row" }}
        contentContainerStyle={{
          paddingVertical: 12,
          flexDirection: "row",
        }}
      >
        <TouchableOpacity
          style={{ marginRight: 16, width: 96 }}
          onPress={() => setNewPlaylistModalVisible(true)}
        >
          <View
            style={{
              width: 96,
              height: 96,
              backgroundColor: "#c3c3c3",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <NText style={{ fontSize: 48, color: "black" }}>+</NText>
          </View>
          <NText style={{ color: colors.text }}>Create Playlist</NText>
        </TouchableOpacity>
        {data?.playlists.map((p) => (
          <TouchableOpacity
            key={p._id}
            style={{ marginRight: 16, width: 96 }}
            onPress={() => props.navigation.navigate("Playlist", { id: p._id })}
          >
            <View
              style={{
                width: 96,
                height: 96,
                backgroundColor: "#c3c3c3",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <Text style={{ fontSize: 48 }}>+</Text> */}
              <NText
                style={{
                  fontSize: 36,
                  color: "black",
                }}
              >
                {p.name.substr(0, 2)}
              </NText>
            </View>
            <NText>{p.name}</NText>
          </TouchableOpacity>
        ))}
      </ScrollView>
    </>
  );
}

const styles = StyleSheet.create({
  groupTitle: { fontWeight: "600", fontSize: 24, marginTop: 8 },
});
