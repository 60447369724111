import { AuthAction, AuthState } from "./types";

const initialState = {
  isLoading: true,
} as AuthState;

export function reducer(state = initialState, action: AuthAction): AuthState {
  switch (action.type) {
    case "RESTORE_TOKEN":
      return {
        ...state,
        userToken: action.token,
        isLoading: false,
      };
    case "SIGN_IN":
      return {
        ...state,
        userToken: action.token,
      };
    case "SIGN_OUT":
      return {
        ...state,
        userToken: null,
      };
    default:
      return state;
  }
}
