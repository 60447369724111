import * as React from "react";
import { Button, View, TextInput, TouchableOpacity, Text } from "react-native";
import { NotabookAPI } from "../api/NotabookAPI";

export function Register({ navigation }: { navigation: any }) {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [name, setName] = React.useState("");
  const [username, setUsername] = React.useState("");

  return (
    <View style={{ alignItems: "center" }}>
      <TextInput
        style={{
          borderWidth: 1,
          paddingHorizontal: 12,
          paddingVertical: 8,
          borderRadius: 4,
          marginTop: 8,
        }}
        placeholder="Email"
        textContentType="emailAddress"
        onChangeText={(text) => setEmail(text)}
      />
      <TextInput
        style={{
          borderWidth: 1,
          paddingHorizontal: 12,
          paddingVertical: 8,
          borderRadius: 4,
          marginTop: 8,
        }}
        placeholder="Full Name"
        textContentType="name"
        onChangeText={(text) => setName(text)}
      />
      <TextInput
        style={{
          borderWidth: 1,
          paddingHorizontal: 12,
          paddingVertical: 8,
          borderRadius: 4,
          marginTop: 8,
        }}
        placeholder="Username"
        textContentType="username"
        onChangeText={(text) => setUsername(text)}
      />
      <TextInput
        style={{
          borderWidth: 1,
          paddingHorizontal: 12,
          paddingVertical: 8,
          borderRadius: 4,
          marginVertical: 8,
        }}
        secureTextEntry
        placeholder="Password"
        textContentType="password"
        onChangeText={(text) => setPassword(text)}
      />
      <Button
        title="Register"
        onPress={() => {
          NotabookAPI.register({
            email,
            password,
            name,
            username,
          }).then((res) => {
            console.warn(res);
            //  dispatch(login("ss"));
            //     navigation.navigate("Home");
          });
        }}
      />
      <TouchableOpacity
        style={{ marginTop: 24 }}
        onPress={() => {
          navigation.navigate("Login");
        }}
      >
        <Text>Already have an account? Login</Text>
      </TouchableOpacity>
    </View>
  );
}
