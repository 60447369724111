import { ISong, Song } from "notabook-shared/models/Song";
import { IPlaylist, IPlaylistSong } from "notabook-shared/models/Playlist";

import { store } from "../store";
import { logout } from "../store/auth/actions";
import { LocalStore } from "./LocalStore";

class NotabookAPIClass {
  baseUrl = __DEV__
    ? "http://192.168.1.192:3005"
    : "https://api.notabook.cancit.com";

  async login({ email, password }: { email: string; password: string }) {
    return this.post("login", { email, password }).then((res) => {
      return res;
    });
  }

  async register({
    email,
    password,
    username,
    name,
  }: {
    email: string;
    password: string;
    username: string;
    name: string;
  }) {
    return this.post("register", { email, password, username, name }).then(
      (res) => {
        return res;
      }
    );
  }

  async searchTrack(params: { query: string }) {
    return this.post("tracks", params);
  }

  async createSong(params: Partial<ISong>) {
    return this.post("songs", params);
  }

  async updateSong(params: Partial<ISong>) {
    return this.post(`songs/${params._id}`, params);
  }

  async getSongs(): Promise<{ songs: Song[] }> {
    return this.get("songs") as any;
  }

  async linkSongWithSpotify(params: {
    songId: string;
    spotifyTrackId: string;
  }) {
    return this.post("songs/linkWithSpotify", params);
  }

  async createPlaylist(params: {
    name: string;
    description: string;
  }): Promise<IPlaylist> {
    return this.post("playlists", params) as any;
  }

  async getPlaylists(): Promise<{ playlists: IPlaylist[] }> {
    return this.get("playlists") as any;
  }

  async addSongToPlaylist(params: {
    playlistId: string;
    songId: string;
  }): Promise<{ playlists: IPlaylist[] }> {
    return this.post(`playlists/${params.playlistId}/songs`, {
      songId: params.songId,
    }) as any;
  }

  async getPlaylistSongs(params: {
    playlistId: string;
  }): Promise<{ songs: IPlaylistSong[] }> {
    return this.get(`playlists/${params.playlistId}/songs`) as any;
  }

  private post(path: string, params: any) {
    return new Promise(async (resolve, reject) => {
      const token = await LocalStore.getToken();
      fetch(this.baseUrl + "/" + path, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Notabook-Access-Token": token!,
        },
        body: JSON.stringify(params),
      })
        .then(async (res) => {
          if (res.status >= 400) {
            if (res.status === 401) {
              store.dispatch(logout());
            }
            reject(await res.json());
          }
          return res.json();
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  private get(path: string) {
    return new Promise(async (resolve, reject) => {
      const token = await LocalStore.getToken();
      fetch(this.baseUrl + "/" + path, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Notabook-Access-Token": token!,
        },
      })
        .then(async (res) => {
          if (res.status >= 400) {
            if (res.status === 401) {
              store.dispatch(logout());
            }
            reject(await res.json());
          }
          return res.json();
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}

export const NotabookAPI = new NotabookAPIClass();
