import React, { useState } from "react";
import { ISong } from "notabook-shared/models/Song";
import { Image, View, TouchableOpacity } from "react-native";
import { useAppTheme } from "../hooks/useAppTheme";
import { NText } from "./NText";
import { NTextInput } from "./NTextInput";
import { SpotifyLinkModal } from "./spotifySearchModal";
import { NotabookAPI } from "../api/NotabookAPI";

export function SongEditor(props: {
  song?: ISong;
  onUpdate: (song: Partial<ISong>) => void;
}) {
  const { colors } = useAppTheme();
  const [song, setSongFunc] = useState<Partial<ISong>>(props.song || {});
  const [spotifyPicker, setSpotifyPicker] = useState(false);

  const setSong = (partial: Partial<ISong>) => {
    setSongFunc((s) => {
      props.onUpdate({ ...s, ...partial });
      return { ...s, ...partial };
    });
  };
  return (
    <>
      {song.name && spotifyPicker && (
        <SpotifyLinkModal
          title={song.name}
          onClose={() => setSpotifyPicker(false)}
          visible={spotifyPicker}
          onSelect={(track) => {
            /*   if (song?._id) {
              NotabookAPI.linkSongWithSpotify({
                songId: song?._id,
                spotifyTrackId: track.id,
              }).then((res) => {
                setSong({
                  name: track.name,
                  artist: track.artist,
                  image: track.image,
                  previewUrl: track.previewUrl,
                  externalTrackId: track.id,
                });
              });
            } else { */
            setSong({
              name: track.name,
              artist: track.artist,
              image: track.image,
              previewUrl: track.previewUrl,
              externalTrackId: track.id,
            });
            //  }
          }}
        />
      )}
      <View style={{ flexDirection: "row", marginTop: 6 }}>
        <TouchableOpacity>
          <View
            style={{
              width: 72,
              height: 72,
              alignItems: "center",
              justifyContent: "center",
              marginRight: 12,
              borderWidth: 1,
              borderColor: colors.border,
            }}
          >
            {song.image ? (
              <Image
                style={{ width: 72, height: 72 }}
                source={{ uri: song.image }}
              />
            ) : (
              <NText>Image</NText>
            )}
          </View>
        </TouchableOpacity>
        <View style={{ flex: 1 }}>
          <NTextInput
            placeholder="Song Name"
            editable={!song.externalTrackId}
            placeholderTextColor={colors.placeholderColor}
            value={song.name}
            style={{
              borderWidth: 1,
              opacity: song.externalTrackId ? 0.5 : 1,
              borderStyle: "solid",
              width: "100%",
              padding: 4,
              borderRadius: 4,
              marginTop: 2,
              borderColor: colors.text,
            }}
            onChangeText={(text) => setSong({ name: text })}
          />
          <NTextInput
            placeholder="Artist"
            editable={!song.externalTrackId}
            placeholderTextColor={colors.placeholderColor}
            value={song.artist}
            style={{
              opacity: song.externalTrackId ? 0.5 : 1,
              borderWidth: 1,
              borderStyle: "solid",
              width: "100%",
              padding: 4,
              borderRadius: 4,
              marginTop: 8,
              borderColor: colors.text,
            }}
            onChangeText={(text) => setSong({ artist: text })}
          />
        </View>
      </View>

      <TouchableOpacity
        style={{
          marginTop: 8,
          borderWidth: 1,
          borderColor: colors.text,
          alignSelf: "flex-start",
          borderRadius: 24,
          opacity: song.name?.length ? 1 : 0.5,
        }}
        disabled={!song.name}
        onPress={() => {
          if (!song.externalTrackId) {
            setSpotifyPicker(true);
          } else {
            setSong({ externalTrackId: undefined, previewUrl: undefined });
          }
        }}
      >
        <NText style={{ paddingVertical: 6, paddingHorizontal: 24 }}>
          {song.externalTrackId ? "UNLINK SPOTIFY" : "LINK WITH SPOTIFY"}
        </NText>
      </TouchableOpacity>
      <NTextInput
        multiline
        placeholder="Notes"
        placeholderTextColor={colors.placeholderColor}
        value={song.content}
        style={{
          padding: 4,
          marginTop: 8,
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: colors.text,
          flex: 1,
          width: "100%",
          borderRadius: 4,
          textAlignVertical: "top",
        }}
        onChangeText={(text) => setSong({ content: text })}
      />
    </>
  );
}
