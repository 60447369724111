import { createStore, combineReducers } from "redux";

import { reducer as songsReducer } from "./songs/reducer";
import { reducer as authReducer } from "./auth/reducer";
import { reducer as configReducer } from "./config/reducer";

import { SongsState } from "./songs/types";
import { AuthState } from "./auth/types";
import { ConfigState } from "./config/types";

export type GlobalState = {
  songs: SongsState;
  auth: AuthState;
  config: ConfigState;
};

export const store = createStore(
  combineReducers({
    songs: songsReducer,
    auth: authReducer,
    config: configReducer,
  }),
  {}
);
