import { NotabookAPI } from "../api/NotabookAPI";
import { useQuery, QueryConfig } from "react-query";
type Unwrap<T> = T extends Promise<infer U>
  ? U
  : T extends (...args: any) => Promise<infer U>
  ? U
  : T extends (...args: any) => infer U
  ? U
  : T;

export function usePlaylists(
  config?: QueryConfig<Unwrap<typeof NotabookAPI.getPlaylists>>
) {
  return useQuery("playlistsData", () => NotabookAPI.getPlaylists(), config);
}

export function usePlaylistSongs(
  playlistId: string,
  config?: QueryConfig<Unwrap<typeof NotabookAPI.getPlaylistSongs>>
) {
  return useQuery(
    `playlistsSongData/${playlistId}`,
    () => NotabookAPI.getPlaylistSongs({ playlistId }),
    config
  );
}
