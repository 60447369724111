import React, { useEffect, useRef } from "react";
import { Song } from "notabook-shared/models/Song";
import { View } from "react-native";
import { SongImage } from "./songImage";
import { SongPreview } from "./songPreview";
import { SongInfo } from "./songInfo";
export function SpotifyCard(props: { song: Song }) {
  const { song } = props;
  return (
    <View style={{ flexDirection: "row" }}>
      <SongImage song={song} />
      <View style={{ flex: 1 }}>
        <SongInfo song={song} />
      </View>
      <SongPreview song={song} />
    </View>
  );
}
