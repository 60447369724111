import React from "react";
import { Song } from "notabook-shared/models/Song";
import { View } from "react-native";
import { NText } from "./NText";
export function SongInfo(props: { song: Song }) {
  const { song } = props;
  return (
    <View style={{ flexDirection: "column" }}>
      {song.name && song.artist ? (
        <>
          <NText
            style={{
              marginLeft: 12,
              fontSize: 18,
              fontWeight: "600",
            }}
          >
            {song.name}
          </NText>
          <NText
            style={{
              marginLeft: 12,
              fontSize: 16,
            }}
          >
            {song.artist}
          </NText>
        </>
      ) : (
        <NText style={{ marginLeft: 12, fontSize: 18, marginVertical: 4 }}>
          {song.title}
        </NText>
      )}
    </View>
  );
}
