import * as React from "react";
import {
  Button,
  View,
  TextInput,
  TouchableOpacity,
  KeyboardAvoidingView,
} from "react-native";
import { useDispatch } from "react-redux";
import { login } from "../store/auth/actions";
import { NotabookAPI } from "../api/NotabookAPI";
import { NText } from "../components/NText";
import { useTheme } from "@react-navigation/native";

export function Login({ navigation }: { navigation: any }) {
  const { colors } = useTheme();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const dispatch = useDispatch();
  return (
    <KeyboardAvoidingView
      style={{ flex: 1 }}
      contentContainerStyle={{}}
      behavior="padding"
    >
      <View
        style={{
          alignItems: "center",
          alignSelf: "center",
          justifyContent: "center",
          width: "100%",
          maxWidth: 250,
          flex: 1,
        }}
      >
        <TextInput
          defaultValue={email}
          style={{
            color: colors.text,
            borderColor: colors.text,
            borderWidth: 1,
            paddingHorizontal: 12,
            paddingVertical: 8,
            borderRadius: 4,
            marginTop: 8,
            alignSelf: "stretch",
          }}
          onChangeText={(text) => setEmail(text)}
          placeholder="Email or Username"
          textContentType="emailAddress"
        />
        <TextInput
          defaultValue={password}
          style={{
            color: colors.text,
            borderColor: colors.text,

            borderWidth: 1,
            paddingHorizontal: 12,
            paddingVertical: 8,
            borderRadius: 4,
            marginVertical: 8,
            alignSelf: "stretch",
          }}
          secureTextEntry
          onChangeText={(text) => setPassword(text)}
          placeholder="Password"
          textContentType="password"
        />
        <Button
          title="Login"
          onPress={() => {
            NotabookAPI.login({
              email,
              password,
            }) /* 
            fetch("http://192.168.1.192:3000/login", {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                email,
              }),
            })
              .then((res) => {
                return res.json();
              })
               */
              .then((res: any) => {
                console.warn(res);
                if (res.accessToken) {
                  dispatch(login(res.accessToken));
                }
                //     navigation.navigate("Home");
              });
          }}
        />
        <TouchableOpacity
          style={{ marginTop: 24 }}
          onPress={() => {
            navigation.navigate("Register");
          }}
        >
          <NText>Don't have an account? Register</NText>
        </TouchableOpacity>
      </View>
    </KeyboardAvoidingView>
  );
}
