import { useTheme } from "@react-navigation/native";
import { Song } from "notabook-shared/models/Song";
import React, { useEffect, useState } from "react";
import {
  Modal as NativeModal,
  Platform,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import { NotabookAPI } from "../api/NotabookAPI";
import { NText } from "./NText";
import { SongImage } from "./songImage";
import { SongInfo } from "./songInfo";
import { SongPreview } from "./songPreview";

const Modal = Platform.select({
  web: require("modal-react-native-web"),
  default: NativeModal,
});
export function SpotifyLinkModal(props: {
  title: string;
  visible: boolean;
  onClose: () => void;
  onSelect?: (track: any) => void;
}) {
  const { colors } = useTheme();
  const [visible, setVisible] = useState(false);
  const [tracks, setTracks] = useState([]);
  const [selected, setSelected] = useState(-1);
  useEffect(() => {
    if (props.visible) {
      search();
    }
  }, [props.visible]);
  const search = () => {
    NotabookAPI.searchTrack({ query: props.title }).then((res) => {
      console.log(res);
      setTracks(res as any);
      setVisible(true);
    });
  };
  return (
    <Modal visible={visible} transparent animationType="fade">
      <View
        style={{
          ...StyleSheet.absoluteFillObject,
          backgroundColor: "black",
          opacity: 0.4,
        }}
      />
      <View
        style={{
          alignItems: "center",
          justifyContent: "center",
          alignSelf: "center",
          flex: 1,
        }}
      >
        <View
          style={{
            borderRadius: 4,
            backgroundColor: colors.card,
            alignSelf: "center",
          }}
        >
          <ScrollView
            style={{
              maxHeight: 500,
            }}
            contentContainerStyle={{
              width: 500,
              alignItems: "center",
              justifyContent: "center",
              alignSelf: "center",
              paddingTop: 8,
            }}
          >
            {tracks?.map((t: any, i) => {
              const song = {
                name: t.name,
                artist: t.artist,
                previewUrl: t.previewUrl,
                image: t.image,
              } as Song;
              return (
                <View
                  style={{
                    flexDirection: "row",
                    flex: 1,
                    alignSelf: "stretch",
                  }}
                >
                  <TouchableOpacity
                    key={t.id + "-t"}
                    style={{
                      marginLeft: 8,
                      marginVertical: 4,
                      alignSelf: "stretch",
                      flexDirection: "row",
                      alignItems: "center",
                      flex: 1,
                    }}
                    onPress={() => {
                      /*   NotabookAPI.linkSongWithSpotify({
                    songId: props.songId,
                    spotifyTrackId: t.id,
                  }).then((res) => {
                    console.log(res);
                    setVisible(true);
                  }); */
                      setSelected(i);
                      console.log(i);
                    }}
                  >
                    <View
                      style={{
                        width: 16,
                        height: 16,
                        marginLeft: 8,
                        marginRight: 16,
                        borderColor: colors.text,
                        borderWidth: 1,
                        backgroundColor:
                          selected === i ? colors.text : "transparent",
                      }}
                    />
                    <SongImage song={song} />
                    <View style={{ flex: 1 }}>
                      <SongInfo song={song} />
                    </View>
                  </TouchableOpacity>
                  <SongPreview key={t.id + "-song"} song={song} />
                </View>
              );
            })}
          </ScrollView>
          <View style={{ flexDirection: "row-reverse" }}>
            <TouchableOpacity
              style={{ marginVertical: 12, marginHorizontal: 12 }}
              onPress={() => {
                props.onClose();
                setVisible(false);
              }}
            >
              <NText>CANCEL</NText>
            </TouchableOpacity>
            <TouchableOpacity
              disabled={selected < 0}
              style={{
                marginVertical: 12,
                marginHorizontal: 12,
                opacity: selected > -1 ? 1 : 0.5,
              }}
              onPress={() => {
                props.onClose();
                setVisible(false);
                props.onSelect?.(tracks[selected]);
              }}
            >
              <NText>LINK</NText>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  );
}
