import * as React from "react";
import {
  NavigationContainer,
  DefaultTheme,
  DarkTheme,
} from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import HomeScreen from "./screens/Home";
import Add from "./screens/Song/Add";
import Edit from "./screens/Song/Edit";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "./store";
import Detail from "./screens/Song/Detail";
import { Login } from "./screens/Login";
import { Register } from "./screens/Register";
import { RootStackParamList, RootLinking } from "./route";
import { Loading } from "./screens/Loading";
import { restoreToken } from "./store/auth/actions";
import { LocalStore } from "./api/LocalStore";
import Playlist from "./screens/Playlist";
import { setGlobalNavigator } from "./navigate";
import { setTheme } from "./store/config/actions";
import { Theme } from "./store/config/types";
const Stack = createStackNavigator<RootStackParamList>();

export function AppNavigator() {
  const dispatch = useDispatch();
  const { isLoading, userToken, theme } = useSelector((state: GlobalState) => ({
    isLoading: state.auth.isLoading,
    userToken: state.auth.userToken,
    theme: state.config.theme,
  }));

  React.useEffect(() => {
    // Fetch the token from storage then navigate to our appropriate place
    const bootstrapAsync = async () => {
      const theme = await LocalStore.getTheme();
      if (theme) {
        dispatch(setTheme(theme as Theme));
      }

      let userToken;
      try {
        userToken = await LocalStore.getToken();
        console.log(userToken);
        dispatch(restoreToken(userToken));
      } catch (e) {
        // Restoring token failed
        dispatch(restoreToken(null));
      }
    };

    bootstrapAsync();
  }, []);

  return (
    <>
      <NavigationContainer
        linking={RootLinking}
        theme={theme === "dark" ? DarkTheme : DefaultTheme}
        ref={(r) => r && setGlobalNavigator(r)}
      >
        <Stack.Navigator>
          {userToken !== null && (
            <>
              <Stack.Screen
                name="Home"
                component={HomeScreen}
                options={{ title: "NotaBook" }}
              />
              <Stack.Screen
                name="AddSong"
                component={Add}
                options={{
                  title: "Add Song",
                }}
              />
              <Stack.Screen
                name="EditSong"
                component={Edit}
                options={{
                  title: "Edit Song",
                }}
              />
              <Stack.Screen
                name="SongDetail"
                component={Detail}
                options={(props) => ({
                  title: "Song",
                })}
              />
              <Stack.Screen
                name="Playlist"
                component={Playlist}
                options={(props) => ({
                  // title: props.route.params?.song?.title,
                })}
              />
            </>
          )}
          {userToken === null && (
            <>
              <Stack.Screen
                name="Login"
                component={Login}
                options={{ title: "NotaBook" }}
              />
              <Stack.Screen
                name="Register"
                component={Register}
                options={{ title: "NotaBook" }}
              />
            </>
          )}
          {isLoading && (
            <Stack.Screen
              name="Loading"
              component={Loading}
              options={{ title: "NotaBook" }}
            />
          )}
        </Stack.Navigator>
        {/* <Player /> */}
      </NavigationContainer>
    </>
  );
}
