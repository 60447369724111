import { AsyncStorage } from "react-native";
import { Theme } from "../store/config/types";

const TOKEN = "com.cancit.token-item";
const THEME = "com.cancit.config.theme";

class LocalStoreClass {
  async setToken(token: string) {
    await AsyncStorage.setItem(TOKEN, token);
  }

  async setTheme(theme: Theme) {
    await AsyncStorage.setItem(THEME, theme);
  }

  getToken(): Promise<string | null> {
    return AsyncStorage.getItem(TOKEN);
  }

  getTheme(): Promise<string | null> {
    return AsyncStorage.getItem(THEME);
  }

  clear() {
    AsyncStorage.clear();
  }
}

export const LocalStore = new LocalStoreClass();
