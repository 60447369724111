import React, { useState, useEffect } from "react";
import { StyleSheet, View, TouchableOpacity, Clipboard } from "react-native";

import { NotabookAPI } from "../../api/NotabookAPI";
import { useSongs } from "../../hooks/useSongs";
import { NTextInput } from "../../components/NTextInput";
import { NText } from "../../components/NText";
import { useAppTheme } from "../../hooks/useAppTheme";
import { SongEditor } from "../../components/songEditor";
import { ISong } from "notabook-shared/models/Song";

export default function Add(props: { navigation: any }) {
  const { colors } = useAppTheme();
  const [song, setSong] = useState<Partial<ISong>>();
  const { refetch } = useSongs({ enabled: false });
  useEffect(() => {
    /*  Clipboard.getString().then((text) => {
      setSong((song) => ({ ...song, content: text }));
    }); */
  }, []);
  const add = () => {
    if (song && song.name && song.artist && song.content) {
      NotabookAPI.createSong(song).then((res: any) => {
        refetch();
        props.navigation.navigate("SongDetail", { id: res._id });
      });
    }
  };

  return (
    <View style={styles.container}>
      <View
        style={{ width: "100%", maxWidth: 960, alignSelf: "center", flex: 1 }}
      >
        <View
          style={{ width: "100%", maxWidth: 960, alignSelf: "center", flex: 1 }}
        >
          <SongEditor onUpdate={(song) => setSong(song)} />
        </View>
        <TouchableOpacity
          onPress={add}
          style={{
            backgroundColor: "#00adfe",
            borderRadius: 24,
            marginTop: 12,
            marginBottom: 24,
            alignSelf: "center",
          }}
        >
          <NText
            style={{
              marginVertical: 8,
              marginHorizontal: 24,
              color: "white",
            }}
          >
            Add
          </NText>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 8,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 8,
  },
});
