import { StatusBar } from "expo-status-bar";
import React, { useLayoutEffect } from "react";
import { StyleSheet, View, TouchableOpacity, ScrollView } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { GlobalState } from "../../store";
import { logout } from "../../store/auth/actions";
import { SongImage } from "../../components/songImage";
import { useSongs } from "../../hooks/useSongs";
import { PlaylistSection } from "./PlaylistSection";
import { useTheme } from "@react-navigation/native";
import { NText } from "../../components/NText";
import { setTheme, switchTheme } from "../../store/config/actions";

export default function HomeScreen(props: { navigation: any }) {
  const items = useSelector((state: GlobalState) => state.songs.items);
  const { colors, dark } = useTheme();

  const { isLoading, error, data } = useSongs();

  const dispatch = useDispatch();
  useLayoutEffect(() => {
    props.navigation.setOptions({
      headerRight: () => (
        <>
          <TouchableOpacity
            style={{ marginRight: 24 }}
            onPress={() => dispatch(switchTheme())}
          >
            <NText>Switch Theme</NText>
          </TouchableOpacity>
          <TouchableOpacity
            style={{ marginRight: 24 }}
            onPress={() => dispatch(logout())}
          >
            <NText>Logout</NText>
          </TouchableOpacity>
        </>
      ),
    });
  }, [props.navigation]);
  if (isLoading || (!data && !error)) return <NText>Loading...</NText>;

  if (error) {
    return <NText>{"An error has occurred: " + (error as any)?.message}</NText>;
  }
  return (
    <View style={{ flex: 1, backgroundColor: colors.background }}>
      <ScrollView
        contentContainerStyle={[
          styles.container,
          { backgroundColor: colors.background },
        ]}
      >
        <PlaylistSection navigation={props.navigation} />
        <NText
          style={[styles.groupTitle, { marginBottom: 8, color: colors.text }]}
        >
          Songs
        </NText>
        {data!.songs
          //  .sort((a, z) => a.title.localeCompare(z.title))
          .map((item) => {
            return (
              <TouchableOpacity
                key={item._id}
                onPress={() => {
                  props.navigation.navigate("SongDetail", { id: item._id });
                }}
                style={{
                  flexDirection: "row",
                  marginVertical: 4,
                }}
              >
                <SongImage song={item} />
                <View>
                  {item.name && item.artist ? (
                    <>
                      <NText
                        style={{
                          marginLeft: 12,
                          fontSize: 18,
                          marginVertical: 4,
                          fontWeight: "600",
                          color: colors.text,
                        }}
                      >
                        {item.name}
                      </NText>
                      <NText
                        style={{
                          marginLeft: 12,
                          fontSize: 16,
                          color: colors.text,
                        }}
                      >
                        {item.artist}
                      </NText>
                    </>
                  ) : (
                    <NText
                      style={{
                        marginLeft: 12,
                        fontSize: 18,
                        marginVertical: 4,
                        color: colors.text,
                      }}
                    >
                      {item.title}
                    </NText>
                  )}
                </View>
              </TouchableOpacity>
            );
          })}
      </ScrollView>
      <TouchableOpacity
        onPress={() => {
          props.navigation.navigate("AddSong");
        }}
        style={{
          backgroundColor: colors.primary,
          borderRadius: 64,
          position: "absolute",
          right: 16,
          bottom: 16,
          width: 56,
          height: 56,
          alignItems: "center",
          justifyContent: "center",
          elevation: 3,
          shadowColor: colors.text,
          shadowOffset: {
            width: 0,
            height: 2,
          },
          shadowOpacity: 0.25,
          shadowRadius: 3.84,
        }}
      >
        <NText
          allowFontScaling={false}
          style={{
            fontSize: 32,
            textAlignVertical: "center",
            marginBottom: 2,
            color: "white",
          }}
        >
          +
        </NText>
      </TouchableOpacity>
      <StatusBar style="dark" />
    </View>
  );
}

const styles = StyleSheet.create({
  groupTitle: { fontWeight: "600", fontSize: 24, marginTop: 8 },
  container: {
    paddingVertical: 16,
    backgroundColor: "#fff",
    paddingHorizontal: 16,
  },
});
