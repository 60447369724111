import { NotabookAPI } from "../api/NotabookAPI";
import { useQuery, QueryConfig } from "react-query";
type Unwrap<T> = T extends Promise<infer U>
  ? U
  : T extends (...args: any) => Promise<infer U>
  ? U
  : T extends (...args: any) => infer U
  ? U
  : T;

export function useSongs(
  config?: QueryConfig<Unwrap<typeof NotabookAPI.getSongs>>
) {
  return useQuery("songData", () => NotabookAPI.getSongs(), config);
}
