import { LocalStore } from "../../api/LocalStore";
import { ConfigAction, ConfigState } from "./types";

const initialState = {
  theme: "dark",
} as ConfigState;

export function reducer(
  state = initialState,
  action: ConfigAction
): ConfigState {
  switch (action.type) {
    case "SET_THEME":
      return {
        ...state,
        theme: action.theme,
      };
    case "SWITCH_THEME":
      const newTheme = state.theme === "dark" ? "light" : "dark";
      LocalStore.setTheme(newTheme);

      return {
        ...state,
        theme: newTheme,
      };

    default:
      return state;
  }
}
