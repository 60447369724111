import { LocalStore } from "../../api/LocalStore";
import { ConfigAction } from "./types";

export function setTheme(theme: "dark" | "light"): ConfigAction {
  LocalStore.setTheme(theme);
  return {
    type: "SET_THEME",
    theme,
  };
}
export function switchTheme(): ConfigAction {
  return {
    type: "SWITCH_THEME",
  };
}
