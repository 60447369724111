import { NavigationContainerRef } from "@react-navigation/native";

let globalNavigator: NavigationContainerRef | undefined;

export function setGlobalNavigator(ref: NavigationContainerRef) {
  globalNavigator = ref;
}

export function navigateToPlaylist(id: string) {
  globalNavigator?.navigate("Playlist", { id });
}
