import React, { useState, useEffect } from "react";
import { StyleSheet, View, TouchableOpacity, Clipboard } from "react-native";

import { NotabookAPI } from "../../api/NotabookAPI";
import { useSongs } from "../../hooks/useSongs";
import { NTextInput } from "../../components/NTextInput";
import { useTheme } from "@react-navigation/native";
import { NText } from "../../components/NText";
import { useAppTheme } from "../../hooks/useAppTheme";
import { StackScreenProps } from "@react-navigation/stack";
import { RootStackParamList } from "../../route";
import { ISong, Song } from "notabook-shared/models/Song";
import { SongEditor } from "../../components/songEditor";

interface Props extends StackScreenProps<RootStackParamList, "EditSong"> {}

export default function EditSong(props: Props) {
  const { colors } = useAppTheme();
  const songId = props.route.params.id;
  const [song, setSong] = useState<ISong>();
  const { data, refetch } = useSongs({ enabled: false });

  useEffect(() => {
    const songsData = data?.songs.find((s) => s._id === songId);
    if (songsData) {
      setSong(songsData);
    } else {
      refetch();
    }
  }, [data]);

  const edit = () => {
    if (song && song.name && song.artist && song.content) {
      NotabookAPI.updateSong(song).then((res: any) => {
        refetch();
        props.navigation.navigate("SongDetail", { id: res._id });
      });
    }
  };
  if (!song) {
    return null;
  }
  return (
    <View style={styles.container}>
      <View
        style={{ width: "100%", maxWidth: 960, alignSelf: "center", flex: 1 }}
      >
        <SongEditor song={song} onUpdate={(song) => setSong(song as Song)} />
        <TouchableOpacity
          onPress={edit}
          style={{
            backgroundColor: "#00adfe",
            borderRadius: 24,
            marginTop: 12,
            marginBottom: 24,
            alignSelf: "center",
          }}
        >
          <NText
            style={{
              marginVertical: 8,
              marginHorizontal: 24,
              color: "white",
            }}
          >
            Save
          </NText>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 8,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 8,
  },
});
